import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private loggedIn = new BehaviorSubject<boolean>(false)
  loggedIn$ = this.loggedIn.asObservable()

  setLoggedIn(value: boolean): void {
    this.loggedIn.next(value)
  }
}
